// extracted by mini-css-extract-plugin
export const zectors_main_div = "process-module--zectors_main_div--2DhDu";
export const img_div = "process-module--img_div--3iSvh";
export const card_secondry = "process-module--card_secondry--28Ear";
export const zector_cards = "process-module--zector_cards--1nFso";
export const zector_card_heading = "process-module--zector_card_heading---0926";
export const zector_title_secondry = "process-module--zector_title_secondry--23uW-";
export const zector_card = "process-module--zector_card--2bT54";
export const card_content = "process-module--card_content--1abpc";
export const zector_client_card = "process-module--zector_client_card--1t_HS";
export const client_avatar = "process-module--client_avatar--1NpvE";
export const card_title = "process-module--card_title--vIxWq";
export const card_img = "process-module--card_img--2DHTj";
export const card_desc = "process-module--card_desc--1YeQy";
export const card_btn = "process-module--card_btn--37fAI";
export const client_main = "process-module--client_main--2xWNb";
export const client_title = "process-module--client_title--2wRdX";
export const title = "process-module--title--lKSN4";
export const zector_img = "process-module--zector_img--27Ndh";