import React from "react";
import * as styles from "./process.module.css";
import Design from "../../images/design.png";

export default function ZectorSecond() {
  return (
    <div className={styles.zector_card_secondry}>
      <div className="container-el container-lg d-flex flex-column item-center">
        <div
          className={`${styles.card_secondry} d-flex flex-wrap-md flex-column-reverse-md jutify-center bg-gray pt-50 pb-50`}
        >
          <div className="flex-1 pr-50-el pl-30 pr-30-lg  pr-15 bs-bb">
            <h3 className="title-el title text-center mb-30 " style={{fontFamily: `Montserrat, sans-serif`}}>Step 2: Design</h3>
            <p className="desc-el desc" style={{fontFamily: `Montserrat, sans-serif`}}>
              During this phase we take a content driven approach to design.
              Before we design any visual components we work with your
              stakeholders to explore your brand identity and determine the copy
              that will be displayed on this application. Once the copy is
              created we take an iterative, revision based approach to visual
              design and wireframing in order to build the desired experience
              for your users. Throughout the design process, we get your
              feedback weekly before implementation.
            </p>
          </div>
          <div
            className={`${styles.zector_img} d-flex justify-center flex-33 align-self-center pl-30 pr-30 pl-50-el pl-15`}
          >
            <div className={styles.img_div}>
              <img className="w-100 " src={Design} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
