import React from "react";
import * as  styles from "./process.module.css";
import Design from "../../images/homedesign.png";

export default function ZectorFrth() {
  return (
    <div className={styles.zector_card_secondry}>
      <div className="container-el container-lg d-flex flex-column item-center">
        <div
          className={`${styles.card_secondry} d-flex flex-wrap-md flex-column-reverse-md jutify-center bg-gray pt-50 pb-50`}
        >
          <div className="flex-1 pr-50-el pl-30 pr-30-lg  pr-15 bs-bb">
            <h3 className="title-el title text-center mb-30 " style={{fontFamily: `Montserrat, sans-serif`}}>Step 4: Test</h3>
            <p className="desc-el desc" style={{fontFamily: `Montserrat, sans-serif`}}>
              During this phase we craft manual and automated testing scripts to
              ensure new code added is operating efficiently. We also perform
              security testing on the application to ensure the application
              meets any compliance standards and verify that the application is
              secure.
            </p>
          </div>
          <div
            className={`${styles.zector_img} d-flex justify-center flex-33 align-self-center pl-30 pr-30 pl-50-el pl-15`}
          >
            <div className={styles.img_div}>
              <img className="w-100 " src={Design} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
