import React from "react";
import * as  styles from "./process.module.css";
import deploy from "../../images/homedeploy.png";

export default function ZectorFifth() {
  return (
    <div className={styles.zectors_main_div}>
      <div className="container-el container-lg d-flex flex-column item-center">
        <div className="d-flex flex-wrap-md flex-column-md justify-center">
          <div
            className={`${styles.zector_img} d-flex justify-center  item-center flex-33 align-self-center pl-30-lg pr-30-lg pr-50-el pl-30 pr-15`}
          >
            <div className={styles.img_div}>
              <img className="w-100 " src={deploy} alt="" />
            </div>
          </div>
          <div className=" flex-1 pl-15 pl-50-el pl-30-lg pr-30 bs-bb">
            <h3 className="title-el title text-center mb-30" style={{fontFamily: `Montserrat, sans-serif`}}>
              Step 5: Deploy{" "}
            </h3>
            <p className="desc-el desc" style={{fontFamily: `Montserrat, sans-serif`}}>
              For our deployment process we set up a development environment in
              the cloud that allows our engineers to deploy quickly and save the
              source code to a repository. We also work with you to mitigate any
              risks during the launch of this project. After a successful launch
              going forward we can accept any feedback, handle any support
              inquiries and rollout new features required by your users.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
