import * as React from "react";
import HeaderCompo from "../components/header/Header";
import Zectorfirst from "../components/process/processfirst";
import ZectorSecond from "../components/process/processSecnd";
import Zectorthird from "../components/process/processthird";
import ZectorFrth from "../components/process/processfrth";
import ZectorFifth from "../components/process/processfifth";
import { Layout } from "../components/Layout";
import zector from "../images/zectorheader.png";
import SEO from "../components/seo";


export default function process() {
  return (
    <>
      <Layout>
      <SEO title="Process | Zector" description="Zector's five-step process for application development."/>
        <HeaderCompo
          headerTitle="Zector's Process"
          headerBtn="Tell us about your project"
          headerbg={zector}
        />
        <Zectorfirst />
        <ZectorSecond />
        <Zectorthird />
        <ZectorFrth />
        <ZectorFifth />
      </Layout>
    </>
  );
}
